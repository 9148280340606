<template>
  <base-section id="chemical">
    <v-container>
      <v-row>
        <v-col
          cols="4"
          sm="4"
          class="text-center"
        >
          <template
            v-for="(card, i) in cards"
          >
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
              size="60"
              @click.native="fnChangeChosen(card)"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>
        <v-col
          cols="1"
          sm="1"
          class="text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="7"
          sm="7"
        >
          <base-subheading
            size="display-1"
            :title="chosen"
            space="0"
          />
          <br>
          <hr>
          <br>
          <v-row>
            <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
            >
              <base-list-item
                :text="item.text"
                class="overflow-x-auto overflow-y-hidden"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionChemical',
    data: () => ({
      chosen: 'RUBBER CHEMICAL INDUSTRY',
      cards: [
        {
          title: 'RUBBER CHEMICAL INDUSTRY',
          color: 'primary',
          dark: true,
          icon: 'mdi-hand-back-left-outline',
        },
        {
          title: 'CHEMICAL INDUSTRY',
          icon: 'mdi-flask-outline',
        },
        {
          title: 'OLEOCHEMICAL INDUSTRY',
          icon: 'mdi-palm-tree',
        },
        {
          title: 'PLASTIC POLYMER INDUSTRY',
          icon: 'mdi-spray-bottle',
        },
      ],
      list: {
        'RUBBER CHEMICAL INDUSTRY': [
          { text: 'ACETIC ACID (90% / 75% / 60%)' },
          { text: 'ACETIC ACID, GLACIAL' },
          { text: 'ACTIVATED BLEACHING EARTH ( ABE )' },
          { text: 'ALUMINIUM HYDROXIDE' },
          { text: 'ALUMINIUM SULPHATE (Granular / Powder)' },
          { text: 'AMMONIA SOLUTION' },
          { text: 'AMMONIUM CHLORIDE' },
          { text: 'AMMONIUM HYDROXIDE' },
          { text: 'AMMONIUM NITRATE (ANFO)' },
          { text: 'AMMONIUM NITRATE (Medical Grade)' },
          { text: 'BENTONITE' },
          { text: 'BORAX' },
          { text: 'CALCIUM CARBIDE' },
          { text: 'CALCIUM CARBONATE (GCC / PCC)' },
          { text: 'CARBON BLACK' },
          { text: 'CALCIUM CHLORIDE' },
          { text: 'CASTOR OIL' },
          { text: 'CAUSTIC POTASH' },
          { text: 'CAUSTIC SODA (Pearl / Flakes / Solid / Liquid)' },
          { text: 'CMC' },
          { text: 'COPPER SULPHATE' },
          { text: 'DIMETHYLFORMAMIDE (DMF)' },
          { text: 'DIOCTYLPHTHALATE (DOP)' },
          { text: 'DMDS' },
          { text: 'FERRIC CHLORIDE (Anhydrous / Solution)' },
          { text: 'FORMALDEHYDE' },
          { text: 'FORMALIN' },
          { text: 'FORMIC ACID (85% / 90% / 94%)' },
          { text: 'FUMARIC ACID' },
          { text: 'FURFURAL' },
          { text: 'GYPSUM PLASTER' },
          { text: 'HEXAMINE' },
          { text: 'HYDROCHLORIC ACID 33%' },
          { text: 'HYDROFLUORIC ACID 55%' },
          { text: 'HYDROGEN PEROXIDE (35% / 50%)' },
          { text: 'LITHARGE' },
          { text: 'MAGNESIUM OXIDE' },
          { text: 'MALEIC ANHYDRIDE (MA)' },
          { text: 'MELAMINE' },
          { text: 'OXALIC ACID' },
          { text: 'PARADICHLOROBENZENE (PDCB)' },
          { text: 'PENTAERITHRITOL' },
          { text: 'PHTHALIC ANHYDRIDE (PA)' },
          { text: 'PHOSPHORIC ACID (TG / FG)' },
          { text: 'POLYETHYLENE WAX (PE WAX)' },
          { text: 'POTASSIUM CARBONATE' },
          { text: 'POTASSIUM CHLORATE' },
          { text: 'POTASSIUM NITRATE' },
          { text: 'POTASSIUM PERMANGANATE' },
          { text: 'PUMICE STONE / POWDER' },
          { text: 'PVC LEAD STABILISERS' },
          { text: 'SODA ASH' },
          { text: 'SODIUM CARBONATE' },
          { text: 'SODIUM FORMATE' },
          { text: 'SODIUM HYDROSULPHIDE' },
          { text: 'SODIUM METABISULPHITE' },
          { text: 'SODIUM METASILICATE (Anhydrous / Pentahydride)' },
          { text: 'SODIUM SILICATE' },
          { text: 'SODIUM SULPHATE ANHYDROUS' },
          { text: 'STYRENE MONOMER' },
          { text: 'SULFAMIC ACID' },
          { text: 'SULPHUR (Powder / Flakes)' },
          { text: 'SULPHURIC ACID 98%' },
          { text: 'TRIMELLITIC ANHYDRIDE (TMA)' },
          { text: 'TRISODIUM PHOSPHATE' },
          { text: 'TITANIUM DIOXIDE (TIO2)' },
          { text: 'UNSATURATED POLYESTER RESINS (UPR)' },
          { text: 'UREA FORMADELHYDE Powder' },
          { text: 'VINYL ACETATE MONOMER (VAM)' },
          { text: 'WHITE OIL' },
          { text: 'ZEOLITE - Synthetic (Detergent Grade)' },
          { text: 'ZINC ASH' },
          { text: 'ZINC CHLORIDE' },
          { text: 'ZINC CARBONATE' },
          { text: 'ZINC SULPHATE' },
        ],
        'CHEMICAL INDUSTRY': [
          { text: 'ACCELARATORS / ANTIOXIDANTS' },
          { text: 'CARBON BLACK' },
          { text: 'ZINC OXIDE' },
          { text: 'NATURAL RUBBER SIR 20 / STR 20 / SVR 20' },
          { text: 'TIREX - Reclaim Rubber' },
          { text: 'SBR 1502 / 1732' },
          { text: 'SULPHUR POWDER MESH 325' },
          { text: 'FORMIC ACID 85%' },
          { text: 'NBR - Latex' },
        ],
        'OLEOCHEMICAL INDUSTRY': [
          { text: 'Stearic Acid' },
          { text: 'Crude Palm Oil' },
          { text: 'Refined Palm Oil' },
          { text: 'Fractioned Fatty Acids' },
          { text: 'Distilled Fatty Acids' },
          { text: 'Glycerine' },
          { text: 'Distilled Fatty Acid Blends' },
          { text: 'Esters' },
        ],
        'PLASTIC POLYMER INDUSTRY': [
          { text: 'CHL.PARAFFIN WAX 52%' },
          { text: 'CAL.STEARATE' },
          { text: 'PVC STABILISERS' },
          { text: 'SILICIC ACID' },
          { text: 'CHL. POLYETHYLENE ( CPE )' },
          { text: 'ESBO' },
          { text: 'PE WAX' },
          { text: 'OLEAMIDE' },
          { text: 'MAGNESIUM STEARATE' },
          { text: 'ZINC STEARATE' },
          { text: 'IMPACT MODIFIERS' },
          { text: 'ANTI-OXIDANT' },
        ],
      },
    }),
    computed: {
      items () {
        return this.list[this.chosen]
      },
    },
    methods: {
      fnChangeChosen: function (card) {
        this.chosen = card.title
        this.cards[0].dark = false
        this.cards[0].color = ''
        this.cards[1].dark = false
        this.cards[1].color = ''
        this.cards[2].dark = false
        this.cards[2].color = ''
        this.cards[3].dark = false
        this.cards[3].color = ''
        card.dark = true
        card.color = 'primary'
      },
    },
  }
</script>
